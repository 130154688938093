@font-face {
  font-family: "PT_Sans";
  src: url('/fonts/PT_Sans-Web-Regular.ttf') format('truetype');
}

@font-face {
  font-family: "PT_Sans";
  src: url('/fonts/PT_Sans-Web-Bold.ttf') format('truetype');
  font-weight: 700;
}

@font-face {
  font-family: "PT_Sans";
  src: url('/fonts/PT_Sans-Web-Italic.ttf') format('truetype');
  font-style: italic;
}

html {
  font-family: PT_Sans;
}

body {
  margin: 0;
}

* {
  box-sizing: border-box;
}

